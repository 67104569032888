import navigate from "lib/navigate";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import qs from "query-string";
import React from "react";
import { autobind } from "react-decoration";
import ReactLoading from "react-loading";
import { IntegrationSearchGroupLightBox } from "./";

const DisabledStyle = {
  "pointer-events": "none",
  opacity: "0.4",
  cursor: "not-allowed",
  display: "inline-block",
  "-webkit-filter": "grayscale(1)",
};

const getIntegrationSearchQueryGroup = gql`
  query getIntegrationSearchQueryGroup($searchForm: SearchForm, $pid: String) {
    getIntegrationSearchQueryGroup(Input: $searchForm) {
      queryGroupList {
        title
        message
        index
        list {
          cnt
          title
          value
          dbID
        }
      }
    }

    dbInfo: getIntegrationSearchDBInfo(pid: $pid) {
      name
      hyintID
      enName
      total
      count
      process
      dbFilter
    }
  }
`;

const toCurrency = (num) => {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

class IntegrationSearchQueryGroup extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(this.props.location.href).query;
    let {
      filterField = [],
      filterValue = [],
      filterValueName = [],
      filterPid,
      pid,
    } = params;

    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);

    var groups = [];
    if (filterValue !== null && filterValue !== undefined) {
      filterField.map((item, key) => {
        if (item !== null && item !== undefined)
          groups.push({
            index: item,
            title: filterValueName[key],
            keyword: filterValue[key],
          });
        return "";
      });

      var group_to_values = groups.reduce((obj, item) => {
        obj[item.index] = obj[item.index] || { keyword: [], title: [] };
        obj[item.index].keyword.push(item.keyword);
        obj[item.index].title.push(item.title);
        return obj;
      }, {});

      groups = Object.keys(group_to_values).map((key) => ({
        index: key,
        keyword: group_to_values[key].keyword,
        title: group_to_values[key].title,
      }));
    }

    let filters = {};

    groups.map((v) => {
      v.keyword.map((v1) => {
        filters[v.index + "-" + v1] = true;
        return "";
      });
      return "";
    });

    this.state = {
      auth: props.readerStore.auth,
      pid,
      filterPid: filterPid,
      fetchGo: -1,
      checkedAll: false,
      filters: filters,
      groups: groups,
      filterField: filterField,
      filterValue: filterValue,
      filterValueName: filterValueName,
      keyword: "",
    };
  }

  componentDidMount() {
    //this.trans();
  }

  UNSAFE_componentWillReceiveProps(props) {
    let params = qs.parseUrl(window.location.href).query;
    let {
      pid = "",
      filterPid = "",
      filterField = [],
      filterValue = [],
      filterValueName = [],
    } = params;

    if (this.state.filterPid !== filterPid) {
      filterField = [].concat(filterField || []);
      filterValue = [].concat(filterValue || []);
      filterValueName = [].concat(filterValueName || []);

      var groups = [];
      if (filterValue !== null && filterValue !== undefined) {
        filterField.map((item, key) => {
          if (item !== null && item !== undefined)
            groups.push({
              index: item,
              title: filterValueName[key],
              keyword: filterValue[key],
            });
          return "";
        });

        var group_to_values = groups.reduce((obj, item) => {
          obj[item.index] = obj[item.index] || { keyword: [], title: [] };
          obj[item.index].keyword.push(item.keyword);
          obj[item.index].title.push(item.title);
          return obj;
        }, {});

        groups = Object.keys(group_to_values).map((key) => ({
          index: key,
          keyword: group_to_values[key].keyword,
          title: group_to_values[key].title,
        }));
      }

      let filters = {};

      groups.map((v) => {
        v.keyword.map((v1) => {
          filters[v.index + "-" + v1] = true;
          return "";
        });
        return "";
      });

      this.setState({
        pid,
        filterPid,
        filterField,
        filterValue,
        filterValueName,
        filters,
        groups,
      });
    }
    //sthis.trans();
  }

  @autobind
  trans() {
    let params = qs.parseUrl(this.props.location.href).query;
    let { filterPid = "" } = params;

    let { search } = this.props;
    params = { ...search };
    let { filterField, filterValue, filterValueName } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);

    var groups = [];
    if (filterValue !== null && filterValue !== undefined) {
      filterField.map((item, key) => {
        if (item !== null && item !== undefined)
          groups.push({
            index: item,
            title: filterValueName[key],
            keyword: filterValue[key],
          });
        return "";
      });

      var group_to_values = groups.reduce((obj, item) => {
        obj[item.index] = obj[item.index] || { keyword: [], title: [] };
        obj[item.index].keyword.push(item.keyword);
        obj[item.index].title.push(item.title);
        return obj;
      }, {});

      groups = Object.keys(group_to_values).map((key) => ({
        index: key,
        keyword: group_to_values[key].keyword,
        title: group_to_values[key].title,
      }));
    }

    let filters = {};

    groups.map((v) => {
      v.keyword.map((v1) => {
        filters[v.index + "-" + v1] = true;
        return "";
      });
      return "";
    });

    this.setState({
      filterPid: filterPid,
      filterField: filterField,
      filterValue: filterValue,
      filters: filters,
      groups: groups,
    });
  }

  @autobind
  addFilter(item, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.filterField = [].concat(params.filterField || []);
    params.filterValue = [].concat(params.filterValue || []);
    params.filterValueName = [].concat(params.filterValueName || []);

    let filters = this.state.filters;
    //let key = "item.field" + "-" + "item.value";
    let key = "item.field-item.value";

    if (filters[key] !== null && filters[key] !== undefined) {
      delete filters[key];
      params = this.deleteSingleFilter(params, item);
    } else {
      if (item.field === "db") {
        filters = [];
        params.filterField = [];
        params.filterValue = [];
        params.filterValueName = [];
        params.filterDBID = "";
      }
      filters[key] = true;
      params = this.addSingleFilter(params, item);
    }

    if (item.field === "keyword") {
      this.setState({ keyword: "" });
    }

    document.querySelector(".filter_search").style = "";
    window.scrollTo(0, 0);
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  clearFilter() {
    let { location } = this.props;
    let params = this.deleteAllFilter();
    window.scrollTo(0, 0);
    navigate([location.pathname, qs.stringify(params)].join("?"));
    document.querySelector(".filter_search").style = "";
  }

  @autobind
  deleteFilter(item) {
    let { location } = this.props;
    let params = this.getParams();
    params = this.deleteSingleFilter(params, item);
    window.scrollTo(0, 0);
    navigate([location.pathname, qs.stringify(params)].join("?"));
    document.querySelector(".filter_search").style = "";
  }

  @autobind
  addSingleFilter(params, d) {
    params.filterField.push(d.field);
    params.filterValue.push(d.value);
    params.filterValueName.push(d.valueName);
    delete params.pageNo;
    if (d.field === "db") {
      params.filterDBID = d.dbID;
    }
    delete params.filterPid;
    document.querySelector(".filter_search").style = "";
    return params;
  }

  @autobind
  deleteSingleFilter(params, d) {
    /*$.each(params.filterField, function (k, v) {
      if (v === d.field && params.filterValue[k] === d.value) {
        params.filterField.splice(k, 1);
        params.filterValue.splice(k, 1);
        params.filterValueName.splice(k, 1);
      }
    });*/
    params.filterField.map((v, k) => {
      if (v === d.field && params.filterValue[k] === d.value) {
        params.filterField.splice(k, 1);
        params.filterValue.splice(k, 1);
        params.filterValueName.splice(k, 1);
      }
      return "";
    });
    delete params.pageNo;
    delete params.filterPid;
    document.querySelector(".filter_search").style = "";
    return params;
  }

  @autobind
  deleteAllFilter() {
    var params = this.getParams();
    delete params.filterField;
    delete params.filterValue;
    delete params.filterValueName;
    delete params.pageNo;
    delete params.filterPid;
    return params;
  }

  @autobind
  getParams() {
    let { search } = this.props;
    let params = { ...search };
    params.filterField = [].concat(params.filterField || []);
    params.filterValue = [].concat(params.filterValue || []);
    params.filterValueName = [].concat(params.filterValueName || []);
    return params;
  }

  toggleAccordion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    let turnicon = e.target.classList.contains("turnicon") ? "" : "none";
    let li = e.target.closest("li");
    li.querySelector(".accordion_content").style.display = turnicon;
  };

  @autobind
  groupUL(item) {
    const { filters } = this.state;
    return item.list.map((field, key2) => {
      if (key2 < 5) {
        let checked = filters[item.index + "-" + field.value] || false;
        const { cnt, title, value, dbID, total } = field;
        const disabled = value === "";
        return (
          <>
            {!checked && (
              <li style={disabled ? DisabledStyle : {}}>
                <a
                  tabIndex="0"
                  onClick={this.addFilter.bind(this, {
                    field: item.index,
                    value,
                    valueName: field.title,
                    dbID,
                  })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.addFilter(
                        {
                          field: item.index,
                          value,
                          valueName: field.title,
                          dbID,
                        },
                        e
                      );
                    }
                  }}>
                  {title}
                  <span>
                    ({toCurrency(cnt)}
                    {total !== null &&
                      total !== undefined &&
                      `/${toCurrency(total)}`}
                    )
                  </span>
                </a>
              </li>
            )}
          </>
        );
      }
      return "";
    });
  }

  @autobind
  handleKeyword(e) {
    e.preventDefault();
    this.setState({ keyword: e.target.value });
  }

  popup = (key) => {
    this.queryGroup.refs["gbox_" + key].popup();
  };

  closeFilterBlock = (e) => {
    e.persist();
    let container = e.target.closest(".container");
    container.querySelector(".filter_search").style = "";
  };

  dbFilter = (e, item) => {
    item.preventDefault();
    //alert(JSON.stringify(item))
    //this.addFilter(item);
  };

  render() {
    let { t } = this.props;
    let { groups, pid, filterPid = "", filters } = this.state;

    if (!this.props.display) {
      return null;
    }

    const LoadingBlock = (
      <div style={{ textAlign: "center" }}>
        <ReactLoading
          type="cylon"
          height={"10%"}
          width={"20%"}
          color="#005D98"
        />
      </div>
    );

    if (filterPid === "") {
      return LoadingBlock;
    }

    const isChinese = this.props.i18n.language === "zh";
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.closeFilterBlock}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.closeFilterBlock(e);
            }
          }}></div>
        <h2>{t("jumperrwd.common.adjustQuery")}</h2>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getIntegrationSearchQueryGroup}
            fetchPolicy="network-only"
            ref={(c) => {
              this.queryGroup = c;
            }}
            variables={{
              searchForm: {
                pid: this.state.filterPid,
              },
              pid,
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading) return LoadingBlock;
              if (error) return "Error!" + error.message;
              if (data.getIntegrationSearchQueryGroup) {
                let { dbInfo, getIntegrationSearchQueryGroup } = data;
                let { queryGroupList } = getIntegrationSearchQueryGroup;

                const dbInfoList = dbInfo.map((item, key) => {
                  const {
                    name,
                    hyintID: dbID,
                    enName,
                    total,
                    count: cnt,
                    dbFilter: value,
                  } = item;
                  const title = !isChinese && enName !== "" ? enName : name;
                  return { cnt, title, value, dbID, total };
                });

                dbInfoList.sort((a, b) => {
                  if (a.total < b.total) {
                    return 1;
                  } else if (a.total > b.total) {
                    return -1;
                  }
                  return 0;
                });

                if (
                  queryGroupList !== null &&
                  queryGroupList !== undefined &&
                  queryGroupList.length > 0
                ) {
                  const spliceIndex = queryGroupList[0].index === "db" ? 1 : 0;
                  queryGroupList.splice(0, spliceIndex, {
                    title: t("jumper.common.database"),
                    index: "db",
                    list: dbInfoList,
                  });
                }
                return (
                  <>
                    <div className="keyword ">
                      <h3>{t("jumperrwd.common.searchFilterBykeyword")}</h3>
                      <form
                        onSubmit={this.addFilter.bind(this, {
                          field: "keyword",
                          value: this.state.keyword,
                          valueName: this.state.keyword,
                        })}>
                        <div className="form_grp form_inline">
                          <input
                            type="text"
                            title={t("jumperrwd.common.enterKeyword")}
                            className=""
                            placeholder={t("jumperrwd.common.enterKeyword")}
                            value={this.state.keyword}
                            onChange={this.handleKeyword}
                          />
                          <input
                            name=""
                            type="submit"
                            title={t("jumper.common.search")}
                            value={t("jumper.common.search")}
                          />
                        </div>
                      </form>
                    </div>

                    {groups.length > 0 && (
                      <>
                        <div className="conditions">
                          <h3>
                            {t("jumperrwd.common.currectAnalysisCondition")}
                          </h3>
                          <ul>
                            {groups.map((item) => (
                              <>
                                <li>
                                  {item.index === "keyword" && (
                                    <h4>{t("jumperrwd.common.byKeyword")}</h4>
                                  )}
                                  {item.index === "db" && (
                                    <h4>{t("jumperrwd.common.byDatabase")}</h4>
                                  )}
                                  {item.index === "author" && (
                                    <h4>{t("jumperrwd.common.byAuthor")}</h4>
                                  )}
                                  {item.index === "journal" && (
                                    <h4>{t("jumperrwd.common.byJournal")}</h4>
                                  )}
                                  {item.index === "subject" && (
                                    <h4>{t("jumperrwd.common.bySubject")}</h4>
                                  )}
                                  {item.index === "year" && (
                                    <h4>{t("jumperrwd.common.byYear")}</h4>
                                  )}
                                  {item.index === "related" && (
                                    <h4>
                                      {t("jumperrwd.common.byRelatedWord")}
                                    </h4>
                                  )}
                                  <ul>
                                    {item.keyword.map((keyword, key2) => {
                                      let label = item.title[key2];
                                      return (
                                        <li>
                                          <button
                                            type="button"
                                            tabIndex="0"
                                            className="del_btn"
                                            onClick={() => {
                                              this.deleteFilter({
                                                field: item.index,
                                                value: keyword,
                                              });
                                            }}>
                                            {t("jumperrwd.common.delete")}
                                          </button>
                                          <span>{label}</span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              </>
                            ))}
                          </ul>
                          <button
                            type="button"
                            tabIndex="0"
                            className="del_all"
                            onClick={this.clearFilter}>
                            {t("jumperrwd.common.clearAll")}
                          </button>
                        </div>
                      </>
                    )}
                    <div className="sort_list">
                      <ul>
                        {queryGroupList &&
                          queryGroupList.map((item, key) => {
                            if (item.index === "year") {
                              item.list.sort((a, b) => {
                                if (a.title < b.title) {
                                  return 1;
                                } else if (a.title > b.title) {
                                  return -1;
                                }
                                return 0;
                              });
                            }
                            if (item.list.length > 0)
                              return (
                                <li>
                                  <h3>
                                    <a
                                      tabIndex="0"
                                      className="turnicon"
                                      onClick={this.toggleAccordion}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.toggleAccordion(e);
                                        }
                                      }}>
                                      {item.message !== ""
                                        ? t(item.message)
                                        : item.title}
                                    </a>
                                  </h3>
                                  <div className="accordion_content">
                                    <ul>{this.groupUL(item)}</ul>
                                    {item.list.length > 0 && (
                                      <>
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="more"
                                          onClick={this.popup.bind(this, key)}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.popup(key, e);
                                            }
                                          }}>
                                          {t("jumperrwd.common.seeMore")}
                                        </button>
                                        <IntegrationSearchGroupLightBox
                                          {...this.props}
                                          ref={"gbox_" + key}
                                          title={
                                            item.message !== ""
                                              ? t(item.message)
                                              : item.title
                                          }
                                          index={item.index}
                                          data={item.list}
                                          filters={filters}
                                          lightboxClass={"filter_lightbox"}
                                          blockClass={"checkblock"}
                                          t={t}
                                        />
                                      </>
                                    )}
                                  </div>
                                </li>
                              );
                            return "";
                          })}
                      </ul>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default IntegrationSearchQueryGroup;
